<template>
  <div>
    <b-container>
      <b-card>
        <b-card-header>
          <h3 class="text-center">{{ addInformation.title }}</h3>
        </b-card-header>
        <b-card-body>
          <span class="row" v-if="addInformation.title == 'News'">
            <label for="header" class="col-md-3">Select tags</label>
            <p hidden>
              {{ tags }}
              {{ selectedTag }}
            </p>

            <div class="col-md-8 row">
              <div class="col-md-3" v-for="(tag, index) in tags" :key="index">
                <b-button
                  :variant="tags[index].active ? 'dark' : 'outline-dark'"
                  style="width: 100%; margin: 5px"
                  @click="apendSelectedTag(tag, index)"
                  >{{ tag.name }}</b-button
                >
              </div>
            </div>
            <div class="col-md-8" v-if="tags.length == 0">
              <p>there is no registered tag</p>
            </div>
            <b-button
              style="width: auto"
              v-b-modal.modal-1
              class="horizontal-right col-md-1"
              variant="success"
              >+</b-button
            >
            <b-modal id="modal-1" @ok="addtag" title="BootstrapVue">
              <p class="my-4">Add Tag</p>

              <b-input
                placeholder="Name of tag name should Tagnamebe uniq"
                v-model="TagName"
              >
              </b-input>
            </b-modal>
          </span>
          <br />
          <label
            for="header"
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Component'
            "
            >{{ addInformation.title }} Image</label
          >
          <label for="header" v-if="addInformation.title == 'People'"
            >Photo</label
          >
          <ImagPicker
            v-if="addInformation.title != 'Component'"
            :imageData="{ maxNoImg: 1, imageNo: 0 }"
          ></ImagPicker>
          <p v-if="bannerImageEmpity" class="text-center" style="color: red">
            Image Can't be empty
          </p>

          <span v-if="addInformation.title == 'Project'">
            <label for="">Logo</label>
            <ImagPicker :imageData="{ maxNoImg: 1, imageNo: 1 }"></ImagPicker>
            <p v-if="iconEmpity" class="text-center" style="color: red">
              Logo Can't be empty
            </p>
          </span>

          <span v-if="addInformation.title == 'Project'">
            <label for="">Theme Color</label>
            <div :style="{ background: theme }">
              <color-picker
                theme="light"
                :color="theme"
                :sucker-hide="false"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                @changeColor="changeColor"
              />
            </div>
          </span>

          <span
            v-if="
              addInformation.title == 'Project' ||
              addInformation.title == 'Knowledge'
            "
          >
            <label for="">Years</label>
            <b-input placeholder="Year" v-model="years"> </b-input>
            <p v-if="yearsEmpity" class="text-center" style="color: red">
              Years Can't be empty
            </p>
          </span>

          <span v-if="addInformation.title == 'Project'">
            <label for="">WebSite</label>
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-globe"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="website"
                placeholder="Website"
              ></b-form-input>
            </b-input-group>
            <label for="">Social Media</label>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-twitter"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="twitter"
                placeholder="twitter link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-facebook"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="facebook"
                placeholder="facebook link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-instagram"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="instagram"
                placeholder="instagram link"
              ></b-form-input>
            </b-input-group>

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><i class="bi bi-linkedin"></i
                ></b-input-group-text>
              </template>
              <b-form-input
                v-model="linkedin"
                placeholder="linkedin link"
              ></b-form-input>
            </b-input-group>
          </span>

          <label for="header" v-if="addInformation.title == 'People'"
            >Full Name</label
          >
          <b-textarea
            v-if="addInformation.title == 'People'"
            v-model="fullName"
          >
          </b-textarea>
          <p class="text-center" style="color: red" v-if="fullNameerr">
            full name cant be empty
          </p>
          <span v-if="addInformation.title != 'Partners'">
            <label for="header">Header</label>
            <b-textarea v-model="header"> </b-textarea>
            <p class="text-center" style="color: red" v-if="headererr">
              header cant be empty
            </p>
          </span>

          <div v-if="addInformation.title == 'News'">
            <label for="example-datepicker">Choose a date</label>

            <b-input-group class="mb-3">
              <b-form-input
                id="example-input"
                v-model="actualDate"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="actualDate"
                  button-only
                  right
                  locale="en-US"
                  aria-controls="example-input"
                  @context="onContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>

          <label for="header" v-if="addInformation.title == 'Project'"
            >Client</label
          >

          <b-input v-if="addInformation.title == 'Project'" v-model="client">
          </b-input>
          <p class="text-center" style="color: red" v-if="clienterr">
            Client can't be empty
          </p>

          <label for="header" v-if="addInformation.title == 'Project'"
            >Target</label
          >
          <div class="mb-2" v-if="addInformation.title == 'Project'">
            <b-button
              variant="outline-dark"
              @click="
                target.push({
                  number: null,
                  title: null,
                })
              "
              >+</b-button
            >
            <div
              class="col-md-12"
              v-for="(element, index) in target"
              :key="index"
            >
              <div class="card">
                <b-input-group>
                  <b-form-input
                    placeholder="Title"
                    v-model="target[index].title"
                  ></b-form-input>
                  <b-form-input
                    type="number"
                    oninput="if(this.value < 0) this.value = 0;"
                    placeholder="Target"
                    v-model="target[index].number"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
          </div>

          <p class="text-center" style="color: red" v-if="targeterr">
            Target can't be empty
          </p>

          <label
            for="header"
            v-if="
              addInformation.title == 'Intervention' ||
              addInformation.title == 'Project'
            "
            >Regions</label
          >
          <b-input
            v-if="
              addInformation.title == 'Intervention' ||
              addInformation.title == 'Project'
            "
            v-model="Regions"
          >
          </b-input>
          <p class="text-center" style="color: red" v-if="regionsterr">
            Regions can't be empty
          </p>

          <div
            v-if="
              addInformation.title != 'Sector' &&
              addInformation.title != 'Partners'
            "
          >
            <label for="">Details</label>
            <quill-editor
              v-model="details"
              ref="myQuillEditor"
              :options="editorOption"
            >
            </quill-editor>
            <p v-if="detailserr" class="text-center" style="color: red">
              Detail Can't be empty
            </p>
          </div>

          <span v-if="addInformation.title == 'Knowledge'">
            <label for="header">File Type</label>
            <b-form-select
              v-model="fileType"
              :options="optionsForFileType"
            ></b-form-select>
          </span>
          <br />
          <span v-if="addInformation.title == 'Knowledge' && fileType == 'pdf'">
            <label for="header">No of pages</label>
            <b-input type="number" v-model="noOfPages"></b-input>
            <p class="text-center" style="color: red" v-if="noOfPageserr">
              number of pages cant be empty
            </p>
          </span>
          <br />

          <span v-if="addInformation.title == 'News'">
            <label for="header">Quotes</label>
            <b-textarea v-model="quotes"> </b-textarea>
          </span>

          <label
            for="header"
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Intervention' &&
              addInformation.title != 'Sector' &&
              addInformation.title != 'Component' &&
              addInformation.title != 'Project' &&
              addInformation.title != 'Knowledge' &&
              addInformation.title != 'Partners'
            "
            >{{ addInformation.title + " Image" }}
          </label>
          <ImagPicker
            v-if="
              addInformation.title != 'People' &&
              addInformation.title != 'Intervention' &&
              addInformation.title != 'Sector' &&
              addInformation.title != 'Component' &&
              addInformation.title != 'Project' &&
              addInformation.title != 'Knowledge' &&
              addInformation.title != 'Partners'
            "
            :imageData="{ maxNoImg: 10, imageNo: 1 }"
          ></ImagPicker>

          <span v-if="addInformation.title == 'Knowledge' && fileType == 'pdf'">
            <label for="">PDF Upload</label>
            <ImagPicker
              :imageData="{ maxNoImg: 1, imageNo: 'filePDF' }"
            ></ImagPicker>
            <p class="text-center" style="color: red" v-if="filePdferr">
              you have to upload the pdf file
            </p>
            <p class="text-center" style="color: red" v-if="fileSizeerr">
              there is some error on uploading your pdf
            </p>
          </span>
        </b-card-body>
        <b-card-body v-if="addInformation.title == 'News'">
          <h3>NEWS detail Information</h3>
          <div class="horizontal-right">
            <b-button
              @click="
                if (subNews.length < 10) {
                  subNews.push({
                    header: null,
                    details: null,
                    quotes: null,
                  });

                  subNewsError.push({
                    headererr: null,
                    detailserr: null,
                  });
                }
              "
              >+</b-button
            >
          </div>
          <p class="horizontal-right error-message" v-if="subNews.length == 10">
            you cant add more than 10
          </p>
          <div class="row">
            <b-card
              class="col-md-6"
              v-for="(sub, index) in subNews"
              :key="index"
            >
              <b-card-body>
                No :{{ index + 1 }} <br />
                <label for="header">Banner Image</label>
                <ImagPicker
                  :imageData="{ maxNoImg: 1, imageNo: 3 + index }"
                ></ImagPicker>

                <label for="header">Header</label>
                <b-textarea v-model="subNews[index].header"> </b-textarea>
                <p
                  class="text-center"
                  style="color: red"
                  v-if="subNewsError[index].headererr"
                >
                  header cant be empty
                </p>
                <label for="header">Details</label>
                <b-textarea v-model="subNews[index].details"></b-textarea>
                <p
                  class="text-center"
                  style="color: red"
                  v-if="subNewsError[index].detailserr"
                >
                  detail cant be empty
                </p>
                <span v-if="addInformation.title == 'News'">
                  <label for="header">Quotes</label>
                  <b-textarea v-model="subNews[index].quotes"> </b-textarea>
                </span>
              </b-card-body>
            </b-card>
          </div>
        </b-card-body>
        <b-card-body>
          <span
            v-if="addInformation.title == 'Knowledge' && fileType == 'video'"
          >
            <label for="">youtube Embedded tag</label>
            <b-textarea v-model="vedioEmbedede"> </b-textarea>
            <p class="text-center" style="color: red" v-if="youtubeerr">
              youTube embedded link cant be empty
            </p>
          </span>

          <!-- select for sectors for inter  -->
          <b-form-group
            label="Select"
            v-if="
              addInformation.title == 'Intervention' ||
              addInformation.title == 'Component' ||
              addInformation.title == 'Project' ||
              addInformation.title == 'Knowledge'
            "
            label-for="tags-with-dropdown"
          >
            <b-form-tags
              id="tags-with-dropdown"
              v-model="value"
              no-outer-focus
              class="mb-2"
            >
              <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-2"
                >
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                      >{{ tag }}</b-form-tag
                    >
                  </li>
                </ul>

                <b-dropdown
                  size="sm"
                  variant="outline-secondary"
                  block
                  menu-class="w-100"
                >
                  <template #button-content>
                    <b-icon icon="tag-fill"></b-icon> Choose
                    <span
                      v-if="
                        addInformation.title == 'Intervention' ||
                        addInformation.title == 'Knowledge'
                      "
                      >Sectors</span
                    >
                    <span v-if="addInformation.title == 'Project'"
                      >Component</span
                    >
                    <span v-if="addInformation.title == 'Component'">
                      Intervention
                    </span>
                  </template>
                  <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group
                      label="Search tags"
                      label-for="tag-search-input"
                      label-cols-md="auto"
                      class="mb-0"
                      label-size="sm"
                      :description="searchDesc"
                      :disabled="disabled"
                    >
                      <b-form-input
                        v-model="search"
                        id="tag-search-input"
                        type="search"
                        size="sm"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    v-for="option in availableOptions"
                    :key="option"
                    @click="onOptionClick({ option, addTag })"
                  >
                    {{ option }}
                  </b-dropdown-item-button>
                  <b-dropdown-text v-if="availableOptions.length === 0">
                    There are no tags available to select
                  </b-dropdown-text>
                </b-dropdown>
              </template>
            </b-form-tags>
          </b-form-group>
        </b-card-body>

        <b-card-footer class="text-center">
          <b-button @click="addField" variant="success"
            ><b-spinner v-if="loading" small></b-spinner> Save
          </b-button>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Vue from "vue";
import ImagPicker from "../Common/ImageUpload.vue";
import colorPicker from "@caohenghu/vue-colorpicker";
const {
  postFields,
  getFields,
  pathField,
  postDataHeader,
} = require("../../assets/js/service");

export default {
  name: "AddField",
  components: {
    ImagPicker,
    colorPicker,
  },
  props: ["addInformation"],
  data() {
    return {
      loading: false,
      theme: "#012970",
      suckerCanvas: null,
      bold: false,
      italic: false,
      linkText: null,
      link: null,
      suckerArea: [],
      vedioEmbedede: null,
      editorOption: null,
      youtubeerr: null,
      actualDate: new Date(),
      dateNews: Date(new Date()).split(" ").slice(0, 4).join(" "),
      isSucking: false,
      optionsForFileType: [
        { text: "PDF", value: "pdf" },
        { text: "Video", value: "video" },
      ],
      years: null,
      fileType: "pdf",
      noOfPages: null,
      noOfPageserr: null,
      yearsEmpity: null,
      filePdferr: null,
      fileSizeerr: null,
      website: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,

      options: [],
      value: [],
      search: "",
      formatted: null,
      allInterventions: null,
      allComponents: null,
      allSectors: null,
      client: null,
      clienterr: null,
      iconEmpity: null,
      target: [
        {
          title: null,
          number: null,
          archived: 0,
        },
      ],
      targeterr: null,
      Regions: null,
      regionsterr: null,
      tags: [],
      TagName: null,
      selectedTag: [],
      header: null,
      fullName: null,
      fullNameerr: null,
      details: null,
      headererr: null,
      detailserr: null,
      quotes: null,
      bannerImageEmpity: null,
      ImageEmpity: null,
      subNews: [
        {
          header: null,
          details: null,
          quotes: null,
        },
      ],
      subNewsError: [
        {
          headererr: null,
          detailserr: null,
        },
      ],
    };
  },
  watch: {
    header(value) {
      this.header = value;
      if (!value && this.addInformation.title != "People") {
        this.headererr = true;
      } else {
        this.headererr = null;
      }
    },
    details(value) {
      this.details = value;
      if (
        !value &&
        this.addInformation.title != "Intervention" &&
        this.addInformation.title != "Sector"
      ) {
        this.detailserr = true;
      } else {
        this.detailserr = null;
      }
    },
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  mounted() {

    this.options = [];
    let token = localStorage.getItem("token");
    if (this.addInformation.title == "News") {

      getFields("tags", token).then((resp) => {
        this.tags = resp.data;
      });
    } else if (
      this.addInformation.title == "Intervention" ||
      this.addInformation.title == "Knowledge"
    ) {
      getFields("Sectors", token).then((resp) => {
        this.allSectors = resp.data;
        this.options = resp.data.map((sector) => sector.header);
      });
    } else if (this.addInformation.title == "Component") {
      getFields("Inerventions", token).then((resp) => {
        this.allInterventions = resp.data;
        let inter = resp.data.filter(
          (intervention) => intervention.componentId == null
        );
        this.options = inter.map((sector) => sector.header);
      });
    } else if (this.addInformation.title == "Project") {
      getFields("Components", token).then((resp) => {
        this.allComponents = resp.data;
        let inter = resp.data.filter(
          (components) => components.projectId == null
        );
        this.options = inter.map((sector) => sector.header);
      });
    }
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    textBold() {
      if (!this.bold) {
        this.details += "<b>";
        this.bold = true;
      } else {
        this.details += "</b>";
        this.bold = false;
      }
    },
    textItalic() {
      if (!this.italic) {
        this.details += "<i>";
        this.italic = true;
      } else {
        this.details += "</i>";
        this.italic = false;
      }
    },
    addBreak() {
      this.details += "<br>";
    },
    addLink() {
      if (this.link && this.linkText) {
        this.details =
          this.details +
          '<a href="' +
          this.link +
          '">' +
          this.linkText +
          "</a>";
      }
    },

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.dateNews = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
    },
    changeColor(color) {
      this.theme = color.hex;
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.selectedSectors = this.search = "";
    },
    apendSelectedTag(tag, index) {
      let temptags = this.tags;
      if (this.tags[index].active) {
        temptags[index].active = false;
        this.tags = temptags;
        this.tags[index]["active"] = false;
        const indextod = this.selectedTag.indexOf(tag.id);
        if (indextod > -1) {
          this.selectedTag.splice(index, 1);
        }
      } else {
        this.selectedTag.push(tag.id);
        temptags[index].active = true;
        this.tags = temptags;
      }
    },
    addtag() {
      let token = localStorage.getItem("token");

      if (this.TagName) {
        postFields("tags", { name: this.TagName }, token).then((resp) => {
          getFields("tags", token).then((resp) => {
            this.tags = resp.data;
          });

          this.$store.commit("set", ["loading", false]);
          Vue.$toast.success("Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
      } else {
        Vue.$toast.error("Tag Name Can't be empty", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    addField() {
      let fieldData = {
        header: this.header,
        details: this.details,
      };
      let token = localStorage.getItem("token");
      if (
        !this.header &&
        this.addInformation.title != "People" &&
        this.addInformation.title != "Partners"
      ) {
        this.headererr = true;
      }
      if (
        !this.details &&
        this.addInformation.title != "Partners" &&
        this.addInformation.title != "Sector"
      ) {
        this.detailserr = true;
      }
      if (!this.headererr && !this.detailserr) {
        if (this.addInformation.title == "News") {
          let database = "news";
          this.bannerImageEmpity = null;
          this.ImageEmpity = null;
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          }

          if (
            !this.subNews[this.subNews.length - 1].header &&
            !this.subNews[this.subNews.length - 1].details &&
            !this.subNews[this.subNews.length - 1].quotes
          ) {
            this.subNews.pop();
            this.subNewsError.pop();
          }
          let validationErrorInSubNews = false;
          for (let index = 0; index < this.subNews.length; index++) {
            const element = this.subNews[index];
            this.subNews[index].bannerImages =
              this.$store.state["images" + (3 + index).toString()][0];

            if (!element.details) {
              this.subNewsError[index].detailserr = true;
              validationErrorInSubNews = true;
            }
          }
          if (
            this.$store.state.images &&
            this.$store.state.images.length != 0 &&
            !validationErrorInSubNews
          ) {
            this.$store.commit("set", ["loading", true]);
            fieldData.bannerImages = this.$store.state.images[0];
            fieldData.newsImages = this.$store.state.images1;
            fieldData.dateNews = this.dateNews;
            fieldData.actualDate = this.actualDate;
            fieldData.quotes = this.quotes;
            fieldData.subNews = this.subNews;
            postFields(database, fieldData, token).then((resp) => {
              for (let index = 0; index < this.selectedTag.length; index++) {
                const element = this.selectedTag[index];
                postFields(
                  "tag-news",
                  { tagId: element, newsId: resp.data.id },
                  token
                );
              }
              this.$store.commit("set", ["images", []]);
              this.$store.commit("set", ["images1", []]);
              this.$store.commit("set", ["images2", []]);
              this.$store.commit("set", ["images3", []]);
              this.$store.commit("set", ["images4", []]);
              this.$store.commit("set", ["images5", []]);
              this.$store.commit("set", ["images6", []]);
              this.$store.commit("set", ["images7", []]);
              this.$store.commit("set", ["images8", []]);
              this.$store.commit("set", ["images9", []]);
              this.$store.commit("set", ["images10", []]);
              this.$store.commit("set", ["images11", []]);
              this.$store.commit("set", ["images12", []]);
              this.$store.commit("set", ["images13", []]);
              this.$store.commit("set", ["images14", []]);
              this.$store.commit("set", ["images15", []]);

              this.tags = [];
              this.TagName = null;
              this.selectedTag = [];
              this.header = null;

              this.details = null;
              this.headererr = null;
              this.detailserr = null;
              this.quotes = null;
              this.bannerImageEmpity = null;
              this.ImageEmpity = null;
              this.subNews = [
                {
                  header: null,
                  details: null,
                  quotes: null,
                },
              ];
              this.subNewsError = [
                {
                  headererr: null,
                  detailserr: null,
                },
              ];
              this.$store.commit("set", ["loading", false]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
          }
        } else if (this.addInformation.title == "People") {
          let database = "People";

          this.bannerImageEmpity = null;
          this.ImageEmpity = null;
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else if (!this.fullName) {
            this.bannerImageEmpity = false;
            this.fullNameerr = true;
          } else {
            this.$store.commit("set", ["loading", true]);
            this.fullNameerr = false;
            fieldData.image = this.$store.state.images[0];
            fieldData.fullName = this.fullName;
            postFields(database, fieldData, token).then((resp) => {
              this.tags = [];
              this.TagName = null;
              this.selectedTag = [];
              this.header = null;
              this.fullName = null;
              this.fullNameerr = null;
              this.details = null;
              this.headererr = null;
              this.detailserr = null;
              this.quotes = null;
              this.bannerImageEmpity = null;
              this.ImageEmpity = null;
              this.subNews = [
                {
                  header: null,
                  details: null,
                  quotes: null,
                },
              ];
              this.subNewsError = [
                {
                  headererr: null,
                  detailserr: null,
                },
              ];
              this.$store.commit("set", ["loading", false]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
          }
        } else if (this.addInformation.title == "Sector") {

          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else {
            this.$store.commit("set", ["loading", true]);
            this.bannerImageEmpity = false;
            fieldData.image = this.$store.state.images[0];
            postFields("Sectors", fieldData, token).then((resp) => {
              this.header = null;
              this.$store.commit("set", ["images", []]);
              this.$store.commit("set", ["loading", false]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
          }
        } else if (this.addInformation.title == "Intervention") {
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else {
            this.bannerImageEmpity = false;
          }

          if (!this.Regions) {
            this.regionsterr = true;
          } else {
            this.regionsterr = false;
          }
          if (
            this.$store.state.images &&
            this.$store.state.images.length != 0 &&
            this.Regions
          ) {
            this.$store.commit("set", ["loading", true]);
            let sectorIds = [];
            this.value.forEach((element) => {
              sectorIds.push(
                this.allSectors.filter((sector) => sector.header == element)[0]
                  .id
              );
            });
            let intervention = {
              header: this.header,
              image: this.$store.state.images[0],
              client: this.client,
              regions: this.Regions,
              sectorIds: sectorIds,
            };

            postDataHeader(
              "Inerventions/createIntervention",
              intervention,
              token
            ).then((resp) => {
              this.header = null;
              this.$store.commit("set", ["images", null]);
              this.client = null;
              this.Regions = null;
              this.value = null;
              this.$store.commit("set", ["loading", false]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
          }
        } else if (this.addInformation.title == "Component") {
          let InterventionIds = [];
          for (let index = 0; index < this.value.length; index++) {
            const element = this.value[index];
            InterventionIds.push(
              this.allInterventions.filter(
                (inetervention) => inetervention.header == element
              )[0]
            );
          }
          this.$store.commit("set", ["loading", true]);
          fieldData.InterventionIds = InterventionIds;
          postDataHeader("Components/createComponent", fieldData, token).then(
            (resp) => {
              //  here here here

              this.header = null;
              this.details = null;
              this.value = null;
              getFields("Inerventions", token).then((resp) => {
                this.allInterventions = resp.data;
                let inter = resp.data.filter(
                  (intervention) => intervention.componentId == null
                );
                this.options = inter.map((sector) => sector.header);
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
            }
          );
        } else if (this.addInformation.title == "Project") {
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else {
            this.bannerImageEmpity = false;
          }
          if (
            !this.$store.state.images1 ||
            this.$store.state.images1.length == 0
          ) {
            this.iconEmpity = true;
          } else {
            this.iconEmpity = false;
          }
          if (!this.client) {
            this.clienterr = true;
          } else {
            this.clienterr = false;
          }
          for (let index = 0; index < this.target.length; index++) {
            const element = this.target[index];
            if (element.title && !element.number) {
              this.target[index].number = 0;
            }

            if (!element.title) {
              this.target.splice(index, 1);
            }
          }

          if (!this.target.length) {
            this.targeterr = true;
          } else {
            this.targeterr = false;
          }
          if (!this.years) {
            this.yearsEmpity = true;
          } else {
            this.yearsEmpity = false;
          }

          if (!this.Regions) {
            this.regionsterr = true;
          } else {
            this.regionsterr = false;
          }

          if (
            this.$store.state.images &&
            this.$store.state.images.length &&
            this.$store.state.images1 &&
            this.$store.state.images1.length &&
            this.client &&
            this.target.length &&
            this.years &&
            this.Regions
          ) {
            this.$store.commit("set", ["loading", true]);
            let project = {
              theme: this.theme.substr(1, this.theme.length),
              details: this.details,
              years: this.years,
              header: this.header,
              client: this.client,
              target: this.target,
              regions: this.Regions,
              facebook: this.facebook,
              website: this.website,
              twitter: this.twitter,
              instagram: this.instagram,
              linkedin: this.linkedin,
              header_image: this.$store.state.images[0],
              logo_image: this.$store.state.images1[0],
            };
            let ComponentIds = [];

            for (let index = 0; index < this.value.length; index++) {
              const element = this.value[index];
              ComponentIds.push(
                this.allComponents.filter(
                  (component) => component.header.trim() == element
                )[0].id
              );
            }
            project.componentIds = ComponentIds;

            postDataHeader("Projects/createProject", project, token).then(
              (resp) => {
                this.theme = null;
                this.details = null;
                this.value = null;
                this.years = null;
                this.client = null;
                this.target = [
                  {
                    title: null,
                    number: null,
                    archived: 0,
                  },
                ];
                this.Regions = null;
                this.facebook = null;
                this.website = null;
                this.twitter = null;
                this.instagram = null;
                this.linkedin = null;
                this.header = null;
                this.$store.commit("set", ["loading", false]);
                this.loading = false;
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            );
          }
        } else if (this.addInformation.title == "Knowledge") {
          let knowledge = {
            header: this.header,
            details: this.details,
            file_type: this.fileType,
            years: this.years,
          };
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else {
            this.bannerImageEmpity = false;
          }
          if (!this.years) {
            this.yearsEmpity = true;
          } else {
            this.yearsEmpity = false;
          }
          if (this.fileType == "pdf") {
            if (
              !this.$store.state.filePDF ||
              this.$store.state.filePDF.length == 0
            ) {
              this.filePdferr = true;
            } else {
              this.filePdferr = false;
              if (!this.$store.state.filePDFSize) {
                this.fileSizeerr = true;
              } else {
                this.fileSizeerr = false;
              }
            }
            if (!this.noOfPages) {
              this.noOfPageserr = true;
            } else {
              this.noOfPageserr = false;
            }
            if (
              this.noOfPages &&
              this.years &&
              this.$store.state.filePDF &&
              this.$store.state.filePDF.length > 0 &&
              this.$store.state.filePDFSize &&
              this.$store.state.images &&
              this.$store.state.images.length > 0
            ) {
              this.$store.commit("set", ["loading", true]);
              let sectorIds = [];
              this.value.forEach((element) => {
                sectorIds.push(
                  this.allSectors.filter(
                    (sector) => sector.header == element
                  )[0].id
                );
              });
              knowledge.tumblin = this.$store.state.images[0];
              knowledge.no_of_pages = this.noOfPages;
              knowledge.pdf = this.$store.state.filePDF;
              knowledge.file_size = this.$store.state.filePDFSize;
              knowledge.sectorIds = sectorIds;
              postDataHeader(
                "Knowledge/createKnowledge",
                knowledge,
                token
              ).then((resp) => {
                this.$store.commit("set", ["images", []]);
                this.$store.commit("set", ["filePDF", []]);
                this.$store.commit("set", ["filePDFSize", null]);
                this.noOfPages = null;
                this.header = null;
                this.details = null;
                this.value = [];
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,

                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
            }
          } else if (this.fileType == "video") {
            if (!this.vedioEmbedede) {
              this.youtubeerr = true;
            } else {
              this.youtubeerr = false;
            }

            if (
              this.vedioEmbedede &&
              this.$store.state.images &&
              this.years &&
              this.$store.state.images.length > 0
            ) {
              this.$store.commit("set", ["loading", true]);
              let sectorIds = [];
              this.value.forEach((element) => {
                sectorIds.push(
                  this.allSectors.filter(
                    (sector) => sector.header == element
                  )[0].id
                );
              });
              knowledge.tumblin = this.$store.state.images[0];
              knowledge.youtube = this.vedioEmbedede;
              knowledge.sectorIds = sectorIds;
              postDataHeader(
                "Knowledge/createKnowledge",
                knowledge,
                token
              ).then((resp) => {
                this.vedioEmbedede = null;
                this.header = null;
                this.details = null;
                this.value = [];
                this.$store.commit("set", ["loading", false]);
                Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,

                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
            }
          }
        } else if (this.addInformation.title == "Partners") {
          this.$store.commit("set", ["loading", true]);
          if (
            !this.$store.state.images ||
            this.$store.state.images.length == 0
          ) {
            this.bannerImageEmpity = true;
          } else {
            this.bannerImageEmpity = false;
          }
          if (
            this.$store.state.images &&
            this.$store.state.images.length != 0
          ) {
            let partners = {
              imageUrl: this.$store.state.images[0],
            };
            postFields("Partners", partners, token).then((resp) => {
              this.$store.commit("set", ["loading", false]);
              this.$store.commit("set", ["images", []]);
              Vue.$toast.success("Successful", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
          }
        }
      }
    },
  },
};
</script>
<style>
</style>